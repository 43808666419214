<template>
  <section class="occupancy"></section>
</template>

<script>
import httpServiceAuth, { getSubdomain } from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import mapMixin from "@/services/mixins/map/map";
const envVariables = process.env;

const { VUE_APP_5DMAPS_URL } = envVariables;

export default {
  name: "5dMaps",
  mixins: [mapMixin],
  created() {
    httpServiceAuth
      .post(apiEndpoints.company.temptoken)
      .then(response => {
        const { access_token } = response.data;
        const subdomain = getSubdomain();
        let subdomainUrl = "";
        if (subdomain) subdomainUrl = `&subdomain=${subdomain}`;
        if (access_token) {
          window.location.replace(
            `${VUE_APP_5DMAPS_URL}?api=${this.getMapApi()}&token=${access_token}${subdomainUrl}`
          );
        }
      })
      .catch(() => {
        this.$router.back();
      });
  }
};
</script>
